
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "1b1e9359-c145-43b8-a3ea-16be4a55aba9"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
