export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '定位可研',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/定位可研.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/%E5%8D%8E%E7%BE%8E%E6%9C%8D%E5%8A%A1%E4%BB%8B%E7%BB%8D/%E5%AE%9A%E4%BD%8D%E5%8F%AF%E7%A0%94.pdf")}`
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=定位可研`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('定位可研/数据库')}`
            },
            {
                name: '在建待建',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2F5.建设%2F1.定位可研%2F4.5.在建待建清单%2F?target_c2=在建待建清单g`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('定位可研/案例库')}`
            },
            {
                name: 'TOD酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/1.定位可研/1.案例库—获奖酒店亮点分析/TOD酒店.pdf")}`
            },
            {
                name: '城市度假酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/1.定位可研/1.案例库—获奖酒店亮点分析/城市度假酒店.pdf")}`
            },
            {
                name: '小型精品酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/1.定位可研/1.案例库—获奖酒店亮点分析/小型精品酒店.pdf")}`
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('定位可研/问题库')}`
            },
            {
                name: '定位可研常见问题',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2F5.建设%2F1.定位可研%2F2.问题库—定位可研%2F定位可研常见问题.json`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('定位可研/工具库')}`
            },
            {
                name: '查酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/查酒店`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}