export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '设计管理',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/设计顾问.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/设计管理.pdf")}`
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=设计管理`
            },
            {
                name: '共建主页',
                img: '/icons/共建主页.svg',
                background: 'black',
                url: `/datapage/${encodeURIComponent('内容文件/5.建设/3.设计管理/1.共建主页—设计单位')}`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('设计管理/数据库')}`
            },
            {
                name: '都长啥样 - 获奖酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/都长啥样—获奖酒店`
            },
            {
                name: '都长啥样 - 新开酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/都长啥样—新开酒店`
            },
            {
                name: '到底是谁 - 设计单位',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f5.建设%2f3.设计管理%2f3.到底是谁—设计单位%2f到底是谁—新开&获奖酒店.pdf`
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('设计管理/案例库')}`
            },
            {
                name: '酒店客房内配置及家具设计反面案例',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/3.设计管理/8.案例库-设计管理/酒店客房内配置及家具设计反面案例.pdf")}`
            },
            {
                name: '酒店公区设计反面案例',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/3.设计管理/8.案例库-设计管理/酒店公区设计反面案例.pdf")}`
            },
            {
                name: '酒店配套设施设计反面案例',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/3.设计管理/8.案例库-设计管理/酒店配套设施设计反面案例.pdf")}`
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('设计管理/问题库')}`
            },
            {
                name: '设计管理常见问题',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f5.建设%2f3.设计管理%2f9.问题库—设计管理%2f设计管理常见问题.json`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('设计管理/工具库')}`
            },
            {
                name: '尽管问',
                img: '/icons/尽管问_白.svg',
                background: 'black',
                url: `/datapage/尽管问`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}