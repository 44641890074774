export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '开业筹备',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/开业筹备.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/开业筹备.pdf")}`
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=开业筹备`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('开业筹备/数据库')}`
            },
            {
                name: '到底是谁 - 新开&获奖酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURIComponent("/内容文件/5.建设/4.采购管理/3.到底是谁—供应商/到底是谁—新开&获奖酒店.pdf")}`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('开业筹备/案例库')}`
            },
            {
                name: '酒店用品采购反面案例',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURIComponent("/内容文件/5.建设/4.采购管理/6.案例库-采购管理/酒店用品采购反面案例.pdf")}`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('开业筹备/问题库')}`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('开业筹备/工具库')}`
            },
            {
                name: '尽管问',
                img: '/icons/尽管问_白.svg',
                background: 'black',
                url: `/datapage/尽管问`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}